<template>
  <section class="w-full py-12 overflow-x-hidden md:py-24"
           ref="container"
  >
    <Container>
      <h2 class="text-2xl md:text-[56px] font-medium text-white md:text-center leading-[1.2] mb-6 lg:b-12">
        {{ title }}
      </h2>
    </Container>

    <Container v-if="showCategories">
      <SectionBlogListingCategories :categories="categories"
                                    :query-param-category="queryParamCategory"
                                    :query-param-page="queryParamPage"
                                    :selected="selectedCategory"
      />
    </Container>

    <GridCardImage class="mt-6 lg:mt-12"
                   :cards="filtered.slice((page - 1) * itemsToShow, (page - 1) * itemsToShow + itemsToShow)"
                   :mobile-cards-as-slider="!pagination"
    />

    <Container>
      <div v-if="cta"
           class="flex mt-8 md:mt-16 md:justify-center"
      >
        <ButtonBase :label="cta.title"
                    :href="cta.href"
                    :type="cta.type"
        />
      </div>

      <SectionBlogListingPagination v-if="pagination"
                                    class="mt-8 md:mt-16"
                                    :query-param="queryParamPage"
                                    :total-items="filtered.length"
      />
    </Container>
  </section>
</template>

<script setup lang="ts">
/**
 * Collection (blog) listing component
 *
 * This component can be used to render items from a given collection.
 *
 * It can be used as a pagination resource (with pagination set to true) or as a component
 * displaying the latest 6 or 9 items.
 *
 * If used a as a pagination resource it is recommended to not use the mobile slider
 * option for a better user experience.
 *
 * It should work with multiple components on the same page, that's why the page
 * query param and the category query param are configurable (with sensible defaults)
 * as they have to be unique on each page.
 */
import { useAsyncData, queryContent } from '#imports';
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { CtaType } from '../../types/button';
import { BlogCollection } from '~/enums';

type BlogListingProps = {
  title: string;
  itemsToShow: number;
  collection: BlogCollection;
  showCategories: boolean;
  pagination: boolean;
  cta: CtaType;
  queryParamPage: string;
  queryParamCategory: string;
};

const props = withDefaults(defineProps<BlogListingProps>(), {
  queryParamPage: 'page',
  queryParamCategory: 'category'
});

const route = useRoute();
const page = ref(route.query[props.queryParamPage] ? Number(route.query[props.queryParamPage]) : 1);
const container = ref<HTMLElement | null>(null);

const { data } = await useAsyncData(`${props.collection}-listing`, () =>
  // eslint-disable-next-line no-undef
  queryContent(props.collection).sort({ date: -1 }).only(['card', 'categories']).find()
);

const generalData = await useAsyncData('general', () => queryContent('globals', 'general').findOne());

const filtered = computed(() => {
  if (data && data.value && data.value.length > 0) {
    // if selectedCategory is not empty, filter stories by category array
    const items = data.value.filter(story => {
      if (selectedCategory.value) {
        return story.categories.some((category: any) => category.slug === selectedCategory.value);
      }

      return true;
    });

    return items.map(item => ({
      ...item.card,
      cta: {
        ...item.card.cta,
        title: resolveCardTitlefromCollection(props.collection)
      }
    }));
  }

  return [];
});

const general = computed(() => {
  if (generalData.data && generalData.data.value) {
    return generalData.data.value;
  }

  return false;
});

function resolveCardTitlefromCollection(collection = BlogCollection.STORIES) {
  if (!general.value) {
    return '';
  }

  if (collection === BlogCollection.STORIES) {
    return general.value.readMoreStoriesCards;
  }

  if (collection === BlogCollection.NEWS) {
    return general.value.readMoreNewsCards;
  }

  if (collection === BlogCollection.PRESS_RELEASES) {
    return general.value.readMorePressReleasesCards;
  }

  if (collection === BlogCollection.MEDIA) {
    return general.value.readMoreMediaCards;
  }

  if (collection === BlogCollection.TECH) {
    return general.value.readMoreTechCards;
  }

  return general.value.readMoreDefault;
}

// selectedCategory computed, read from query string sc
const selectedCategory = computed(() => {
  if (route.query[props.queryParamCategory]) {
    return route.query[props.queryParamCategory] as string;
  }

  return '';
});

/**
 * Get all categories from stories, flatten them, remove duplicates
 */
const categories = computed(() => {
  if (data && data.value && data.value.length > 0 && props.showCategories) {
    return data.value
      .map(item => item.categories)
      .flat()
      .filter((category, index, self) => {
        return self.findIndex(c => c.slug === category.slug) === index;
      });
  }

  return [];
});

// watch route.query.page and set it to page.value
watch(
  () => route.query[props.queryParamPage],
  () => {
    page.value = route.query[props.queryParamPage] ? Number(route.query[props.queryParamPage]) : 1;

    // Scroll into view next tick
    nextTick(() => {
      if (container.value) {
        container.value.scrollIntoView(true);
      }
    });
  }
);
</script>
